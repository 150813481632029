
const datas = [
  {
    destination: '🍑 twdlc',
    line: '45',
    stop: [
      {
        station: 'Jardin botanique',
        id: '8412',
        bus: ['3007'],
      },
      {
        station: 'Jean Giono',
        id: '8403',
        bus: ['3007'],
      },
      {
        station: 'Darwin',
        id: '1513',
        bus: ['3007'],
      },
    ],
  },
  {
    destination: '🏠 home',
    line: '45',
    stop: [
      {
        station: 'Les Hangars',
        id: '6838',
        bus: ['5742', '8003'],
      },
      {
        station: 'Cours du Médoc',
        id: '6465',
        bus: ['5742', '8003'],
      },
    ],
  },
  {
    destination: '🚝 centre ville',
    line: 'B',
    stop: [
      {
        station: 'Les Hangars',
        id: '5258',
        bus: ['5247', '7440'],
      },
    ],
  }
];

export default datas;