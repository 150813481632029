import useSWR from 'swr';

import { fetcher } from './utils';

const useTbmApi = (id, line) => {
  const { data, error } = useSWR(`https://ws.infotbm.com/ws/1.0/get-realtime-pass/${id}/${line}`, fetcher, {
    refreshInterval: 1000 * 30,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
};

export default useTbmApi;
