import { useMemo, useState } from 'react';

import useTbmApi from './useTbmApi';
import datas from './datas';

import './App.css';

const Card = ({ content, destination }) => {
  const req = useTbmApi(content.id, destination.line);

  const renderDestinations = useMemo(() => {
    if (!req.data || !req.data.destinations) {
      return null;
    }

    const destinations = content.bus.reduce((acc, obj) => {
      const dest = req.data.destinations[obj];
      if (dest) {
        acc.push(...dest);
      }
      return acc;
    }, []).sort((a, b) => (
      new Date(a.arrival_theorique).getTime() - new Date(b.arrival_theorique).getTime()
    ));

    if (destinations.length > 0) {
      return destinations.map(bus => (
        <span className={'waittime'}  key={bus.waittime_text}>{bus.waittime_text}</span>
      ));
    }

    return <span>Pas de départs 😩</span>;
  }, [req, content.bus]);

  return (
    <div className={'card'}>
      {req.isLoading && <span>Chargement 🔄</span>}
      {req.isError && <span>{req.isError}</span>}
      <p>{content.station}</p>
      {renderDestinations}
    </div>
  );
};

function App() {
  const [currentDestination, setDestination] = useState(null);

  const selectDestination = (dest) => {
    setDestination(dest);
  };

  if (currentDestination) {
    return (
      <div className={'App'} key={'loaded'}>
        <div className={'time-card'}>
          <h1>{currentDestination.destination}</h1>
          <div className={'card-container'}>
            {currentDestination.stop.map(stop => <Card key={stop.id} content={stop} destination={currentDestination} />)}
          </div>
          <div
            className={'back-button'}
            onClick={() => selectDestination(null)}>
            ⬅️ destination
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'App'}>
      <h1>🚍 Destination</h1>
      <div className={'destination-container'}>
        {datas.map(destination => (
          <div
            key={destination.destination}
            className={'destination-card'}
            onClick={() => selectDestination(destination)}>
            {destination.destination}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
